import { createOrEditItem } from './form'
import { connectedApiService } from '..'

export const updateAccountStatement = values => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        items: values,
      },
      {
        endpoint: 'updateStatment',
        successMessage: 'Invoice was successfully updated',
      }
    )
  )

export const generateAccountInvoice = (info, values) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {
        companyInfo: info,
        items: values,
      },
      {
        endpoint: 'generateStatementOfAccountInvoice',
        successMessage: 'Invoice was successfully generated',
      }
    )
  )

export const generateExcelFile = (payoutId, type) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'get',
        endpoint: `generateExcelSheet/${payoutId}/${type}`,
        successMessage: 'Excel sheet was successfully generated',
      }
    )
  )
